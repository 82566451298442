import { Injectable } from '@angular/core';
import { ICalendarEvent, ICommittee, IMember, RRuleModel } from '@common/types';
import { MatDialog } from '@angular/material/dialog';
import { getRequired } from '@common/utils/form.helpers';
import { UUID_EMPTY } from '@common/constants';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { CalendarEventService, CommitteeService } from '@common/services';
import { EventPlanningComponent } from '@common/dialogs/event-planning/event-planning.component';
import {
  EventPlanningClose,
  EventPlanningProps,
  EventPlanningType
} from '@common/dialogs/event-planning/types';
import { RoleAccessesEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class EventPlanningService {
  constructor(
    private matDialog: MatDialog,
    private committeeService: CommitteeService,
    private calendarEventService: CalendarEventService
  ) {}

  public openTransfer(eventId: string, disconnectCall = false): Observable<EventPlanningClose> {
    return forkJoin([
      this.calendarEventService.retrieveCalendarEvent(eventId),
      this.calendarEventService.retrieveEventMembers(eventId)
    ]).pipe(
      switchMap(([event, members]) => {
        return this.matDialog
          .open(EventPlanningComponent, {
            panelClass: ['committees-app'],
            maxWidth: '96vw',
            data: this.getDialogDataTransfer(event, members, disconnectCall),
            disableClose: true
          })
          .afterClosed();
      })
    );
  }

  private getDialogDataTransfer(
    event: ICalendarEvent,
    members: IMember[],
    disconnectCall: boolean
  ): EventPlanningProps {
    const { rrule, committeeId, committeeMeetingRooms } = event;

    const required = members
      .filter((m) => m.memberAccesses.includes(RoleAccessesEnum.REQUIRED_TO_ATTEND_THE_COMMITTEE))
      .map((m) => ({
        id: m.committeeMember.employee.id,
        shortName: m.committeeMember.employee.shortName,
        fullName: m.committeeMember.employee.fullName,
        position: m.committeeMember.employee.position,
        isActive: m.committeeMember.employee.isActive
      }));

    return {
      employeeIds: required.map((e) => e.id),
      roomIds: committeeMeetingRooms.map((r) => r.id as string).filter((e) => e !== UUID_EMPTY),
      rRule: rrule as RRuleModel,
      committeeId,
      canceled: null,
      title: 'Перенос события',
      employees: required,
      disconnectCall,
      type: EventPlanningType.transfer,
      eventId: event.id
    };
  }

  public openUnplanned(committeeId: string, canceled: boolean | null = null): Observable<ICalendarEvent> {
    return this.committeeService.retrieveCommittee(committeeId).pipe(
      switchMap((committee) => {
        return this.matDialog
          .open(EventPlanningComponent, {
            panelClass: ['committees-app'],
            maxWidth: '96vw',
            data: this.getDialogDataUnplanned(committee, canceled)
          })
          .afterClosed();
      })
    );
  }

  private getDialogDataUnplanned(committee: ICommittee, canceled: boolean | null): EventPlanningProps {
    const { committeeMembers, committeeMeetingRooms: roomIds, rrule } = committee.compromiseModel;
    const required = getRequired(committeeMembers);

    const employees = required.map((m) => ({
      id: m.employee.id,
      shortName: m.employee.shortName,
      fullName: m.employee.fullName,
      position: m.employee.position,
      isActive: m.employee.isActive
    }));

    return {
      employeeIds: employees.map((m) => m.id),
      roomIds: roomIds.filter((e) => e !== UUID_EMPTY),
      rRule: rrule as RRuleModel,
      committeeId: committee.id,
      canceled: canceled,
      title: 'Создание внепланового события',
      employees,
      disconnectCall: false,
      type: EventPlanningType.unplannedEvent,
      eventId: null
    };
  }
}
