<div
  [ngClass]="{
    'invisible max-h-px max-w-px overflow-hidden': times?.length === 0 && days?.length === 0
  }"
  class="flex items-center justify-between"
>
  <div class="flex gap-6 items-center max-h-10 min-h-10">
    <ekp-select
      #dateSelect
      placeholder="Выберите доступную дату"
      [attr.value]="selectedDate"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [attr.clearable]="false"
    >
      <ng-container *ngFor="let slot of days">
        <ekp-select-item [attr.value]="slot.date">
          {{ slot.view }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>
    <ekp-select
      #timeSelect
      [ngClass]="{ 'invisible max-h-px max-w-px overflow-hidden absolute': timeLoading }"
      [attr.value]="selectedTime"
      placeholder="Выберите доступное время"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [attr.clearable]="false"
    >
      <ng-container *ngFor="let slot of times">
        <ekp-select-item [attr.value]="slot.timeStart">
          {{ slot.view }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>
    <ekp-select
      *ngIf="showReason"
      [ngClass]="{ 'invisible max-h-px max-w-px overflow-hidden absolute': timeLoading }"
      #reasonSelect
      placeholder="Выберите причину"
      size="lg"
      class="min-w-[300px] max-w-[300px]"
      [attr.clearable]="false"
      [attr.value]="transferReasons[0].id"
    >
      <ng-container *ngFor="let reason of transferReasons">
        <ekp-select-item [attr.value]="reason.id" [matTooltip]="reason.name">
          {{ reason.name }}
        </ekp-select-item>
      </ng-container>
    </ekp-select>

    <div *ngIf="timeLoading" class="flex justify-center items-center gap-2 px-2">
      <mat-spinner [diameter]="24"></mat-spinner>
      Загружаем данные..
    </div>
  </div>

  <ng-content></ng-content>
</div>
<div
  *ngIf="times?.length === 0 && days?.length === 0"
  class="text-[16px] text-[#EE5B5B] w-full flex justify-center"
>
  На этот день нет свободных слотов..
</div>
