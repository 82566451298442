import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppRolesEnum, CommitteeEventStatusEnum, ResolutionTypeEnum } from '@common/enums';
import {
  IAttachmentDto,
  IEmployee,
  IEmployeeCurrentUserInfo,
  IEmployeeOption,
  IEventAgenda,
  IEventResolution,
  IMember,
  IPermission,
  ISpeaker,
  IUploadConditionsDto
} from '@common/types';

@Component({
  selector: 'com-resolution-block',
  templateUrl: './resolution-block.component.html'
})
export class ResolutionBlockComponent implements OnInit {
  @Input() committeeEventId: string;
  @Input() committeeEventStatus: CommitteeEventStatusEnum;
  @Input() currentUser: IEmployeeCurrentUserInfo;
  @Input() employees: IEmployeeOption[] = [];
  @Input() socketUpdate = false;
  @Input() isEventActive = false;
  @Input() editable = false;
  @Input() permission: IPermission;
  @Input() eventStarted = false;
  @Input() eventEnded = false;
  @Input() preResolutionUploadConditions: IUploadConditionsDto;
  @Input() agenda: IEventAgenda;
  @Input() members: IMember[] = [];
  @Input() isCall = false;
  @Input() preResolutionUploadDeadline: string;

  @Output() valueChange = new EventEmitter<IEventResolution[]>();
  @Output() documentShow = new EventEmitter<IAttachmentDto>();

  public preResolutionResponsible: IEmployee[];

  protected readonly ResolutionTypeEnum = ResolutionTypeEnum;
  protected readonly AppRolesEnum = AppRolesEnum;

  ngOnInit(): void {
    this.preResolutionResponsible = this.getUploadPreResolutionEmployees();
  }

  public onEventResolutionsChange(eventResolutions: IEventResolution[]): void {
    this.valueChange.emit(eventResolutions);
  }

  private getUploadPreResolutionEmployees(): IEmployee[] {
    if (this.preResolutionUploadConditions?.responsible) {
      return [this.preResolutionUploadConditions.responsible];
    }

    if (this.agenda.responsiblePreResolution) {
      return [this.agenda.responsiblePreResolution];
    }

    return (this.agenda.speakers as ISpeaker[])
      .filter((speaker) => speaker.needLoadedPreResolution)
      .map((speaker) => speaker.employee);
  }
}
