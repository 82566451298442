import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICommitteeItem } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private committeeTypes = new BehaviorSubject<ICommitteeItem[]>([]);
  private committeeSubTypes = new BehaviorSubject<ICommitteeItem[]>([]);
  private committeeKinds = new BehaviorSubject<ICommitteeItem[]>([]);
  private committeeDivisions = new BehaviorSubject<ICommitteeItem[]>([]);
  private committeeFormats = new BehaviorSubject<ICommitteeItem[]>([]);

  constructor(private http: HttpClient) {
    this.loadCommitteeTypes();
    this.loadCommitteeSubTypes();
    this.loadCommitteeKinds();
    this.loadCommitteeDivisions();
    this.loadCommitteeFormats();
  }

  get committeeTypes$(): Observable<ICommitteeItem[]> {
    return this.committeeTypes.asObservable();
  }
  get committeeSubTypes$(): Observable<ICommitteeItem[]> {
    return this.committeeSubTypes.asObservable();
  }
  get committeeKinds$(): Observable<ICommitteeItem[]> {
    return this.committeeKinds.asObservable();
  }
  get committeeDivisions$(): Observable<ICommitteeItem[]> {
    return this.committeeDivisions.asObservable();
  }

  private loadCommitteeTypes(): void {
    this.http.get<ICommitteeItem[]>('api/CommitteeType').subscribe((committeeTypes) => {
      this.committeeTypes.next(committeeTypes);
    });
  }

  private loadCommitteeSubTypes(): void {
    this.http.get<ICommitteeItem[]>('api/CommitteeSubType').subscribe((committeeSubTypes) => {
      this.committeeSubTypes.next(committeeSubTypes);
    });
  }

  private loadCommitteeKinds(): void {
    this.http.get<ICommitteeItem[]>('api/CommitteeKind').subscribe((committeeKinds) => {
      this.committeeKinds.next(committeeKinds);
    });
  }

  private loadCommitteeDivisions(): void {
    this.http.get<ICommitteeItem[]>('api/Division').subscribe((committeeDivisions) => {
      this.committeeDivisions.next(committeeDivisions);
    });
  }

  private loadCommitteeFormats(): void {
    this.http.get<ICommitteeItem[]>('api/CommitteeFormat').subscribe((committeeFormats) => {
      this.committeeFormats.next(committeeFormats);
    });
  }
}
