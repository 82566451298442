import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { ICommitteeEventData, IMember, IEventAgenda, IUploadAttachment, IAttachmentDto } from '@common/types';
import { FormInputComponent } from '@common/shared';
import {
  BUTTON_SPINNER_DIAMETER,
  CommitteeMemberActions,
  DATE_TIME_FORMAT,
  SYSTEM_USER,
  URL_FORMAT
} from '@common/constants';
import { EmployeeService, JitsuLoggerService } from '@common/services';
import { AppRolesEnum } from '@common/enums';

@Component({
  selector: 'com-event-materials',
  templateUrl: './event-materials.component.html'
})
export class EventMaterialsComponent {
  @Input() agenda: IEventAgenda;
  @Input() currentMember: IMember;
  @Input() canUploadMaterial = true;
  @Input() canRestrictMaterialDownload = false;
  @Input() canRestrictMaterialView = false;
  @Input() canMakeCommentsOnTheProvidedMaterials = false;
  @Input() committee: ICommitteeEventData;
  @Input() eventTime: string;
  @Input() protocolSent: boolean;
  @Input() materialUploadDeadline: string;
  @Input() isMaterialLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output() setViolationClick = new EventEmitter<IAttachmentDto>();
  @Output() configAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() downloadAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() deleteAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() materialUpload = new EventEmitter<IUploadAttachment>();

  @ViewChild('fileInput', { static: false })
  fileInput: FormInputComponent;
  @ViewChild('linkInput', { static: false })
  linkInput: FormInputComponent;

  public BUTTON_SPINNER_DIAMETER = BUTTON_SPINNER_DIAMETER;
  public linkControl = new FormControl<string>(null, [Validators.required, Validators.pattern(URL_FORMAT)]);
  public currentDate = moment().format(DATE_TIME_FORMAT);
  public addLinkBlockShown = false;
  public isRequiredLoading = false;
  public isRequiredShowBlock = false;
  public systemUser = SYSTEM_USER;
  protected readonly AppRolesEnum = AppRolesEnum;

  constructor(
    private _jitsuLoggerService: JitsuLoggerService,
    public employeeService: EmployeeService
  ) {}

  public onLinkUploadBlockAdd(required: boolean): void {
    this.addLinkBlockShown = true;
    this.isRequiredShowBlock = required;
  }

  public onMaterialUpload(event: Event, required: boolean): void {
    this.isRequiredLoading = required;
    const target = event.target as HTMLInputElement;
    if (target.files.length) {
      this.materialUpload.emit({ target, required });
      target.value = '';
    }
  }

  public onDeleteAttachmentClick({
    material,
    required
  }: {
    material: IAttachmentDto;
    required: boolean;
  }): void {
    this.isRequiredLoading = required;
    this.deleteAttachmentClick.emit(material);
  }

  public onSetViolation(material: IAttachmentDto): void {
    this._jitsuLoggerService.logEvent(CommitteeMemberActions.eventAddViolationToMaterial);
    this.setViolationClick.next(material);
  }

  public onLinkAdd(required: boolean): void {
    this.isRequiredLoading = required;
    this.materialUpload.next({
      link: `${this.linkInput.control.value}`,
      required
    });
    this.onLinkUploadBlockDelete();
  }

  public onLinkUploadBlockDelete(): void {
    this.linkControl.reset();
    this.addLinkBlockShown = false;
  }
}
