import { KeycloakConfig } from 'keycloak-js';

type Url = string;

export interface IEnvironment {
  production: boolean;
  keycloak: KeycloakConfig;
  api: Url;
  calendarApi: Url;
  constructorApi: Url;
  taskManagerApi: Url;
  wsHost: Url;
  jitsuLogging: IJitsuLogging;
  jitsiUrl: Url;
  wiki: Url;
}

export interface IJitsuLogging {
  hostUrl: Url;
  apiKey: string;
}
