import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CalendarEventService,
  CalendarService,
  CommitteeEventActionService,
  JitsuLoggerService,
  UnsubscribeService
} from '@common/services';
import { IntersectionCommitteeService } from '@common/dialogs/planning-dialog/services/intersection-committee.service';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  finalize,
  Observable,
  of,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import { DateInfo, DayType, FreeDay, FreeSlot, TimeInterval } from '@common/dialogs/planning-dialog/types';
import { WeekDayCell } from '@common/dialogs/planning-dialog/modules/planning-diagram/types';
import { format } from 'date-fns';
import { FreeSlotSelect } from '@common/dialogs/planning-dialog/modules/planning-intersection/types';

import { IntersectionDiagramService } from '@common/dialogs/planning-dialog/modules/planning-diagram/services/intersection-diagram.service';
import { CellType } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import { setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { ru } from 'date-fns/locale';
import { isDayAfter, startOfDayString } from '@common/dialogs/planning-dialog/utils';
import { EventPlanningProps, EventPlanningType } from './types';
import { LEGEND } from '@common/dialogs/event-planning/const';
import { CommitteeEventActions } from '@common/constants';
import { ICalendarCommitteeEvent, RRuleModel } from '@common/types';
import { BusyTimelineService } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/services/busy-timeline.service';
import { BusyTimeline } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/types';

@Component({
  selector: 'com-event-planning',
  templateUrl: 'event-planning.component.html',
  styleUrls: ['event-planning.component.scss'],
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventPlanningComponent {
  public transferDate: FreeSlotSelect | null = null;
  public reason: string | null = null;
  public EventPlanningType = EventPlanningType;
  public freeDays: FreeDay[] = [];
  public freeTime: FreeSlot[] = [];
  public selectedDay = '';
  public cellSize = 16;
  public Legends = LEGEND;
  public freeDaysNotFound = false;
  public error = false;
  public timeline: BusyTimeline[] = [];
  public timelineDate: string;
  private excludeLunchTime = true;

  public dayChange$ = new Subject<string>();
  public loading$ = new BehaviorSubject<boolean>(true);
  public dayLoading$ = new BehaviorSubject<boolean>(false);
  public showFreeSlots$ = new BehaviorSubject<boolean>(false);
  public loadEvents$ = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<EventPlanningComponent>,
    public diagramService: IntersectionDiagramService,
    private intersectionService: IntersectionCommitteeService,
    private jitsuLoggerService: JitsuLoggerService,
    private calendarEventService: CalendarEventService,
    private eventActionService: CommitteeEventActionService,
    private calendarService: CalendarService,
    private busyTimelineService: BusyTimelineService,
    @Inject(MAT_DIALOG_DATA) public props: EventPlanningProps,
    @Self() private unsubscribe: UnsubscribeService
  ) {
    this.initDiagram();
    this.loadEvents();
    this.dayChangeSub();
    this.loadEventsSub();
  }

  private loadEventsSub(): void {
    this.loadEvents$
      .pipe(
        debounceTime(300),
        tap(() => this.loadEvents()),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private initDiagram(): void {
    const { rRule } = this.props;
    this.diagramService.initWeekDayMap(rRule, new Date());
  }

  private loadEvents(): void {
    this.loading$.next(true);

    this.loadEventsSwitcher()
      .pipe(
        tap((days) => {
          this.diagramService.updateByTransfer(days);
          this.initFreeDays(days);
          const freeDay = days.find((d) => d.type === DayType.Free);
          if (freeDay) {
            this.freeDaysNotFound = false;
            this.selectedDay = startOfDayString(freeDay.date);
            this.setTimelineDate(setTimeZone(freeDay.date));
            this.getDayInfo(setTimeZone(freeDay.date));
            this.showFreeSlots$.next(true);
          } else {
            this.freeDaysNotFound = true;
            this.loading$.next(false);
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private loadEventsSwitcher(): Observable<DateInfo[]> {
    const { rRule, eventId, employeeIds, roomIds, type, committeeId } = this.props;
    const updatedRrule = this.getUpdatedRrule(rRule);
    if (type === EventPlanningType.transfer) {
      return this.intersectionService.transferEvent(updatedRrule, eventId, employeeIds, roomIds);
    } else {
      return this.intersectionService.unplannedEvent(updatedRrule, committeeId, employeeIds, roomIds);
    }
  }

  private getUpdatedRrule(rRule: RRuleModel): RRuleModel {
    return {
      ...rRule,
      excludeLunchTime: this.excludeLunchTime
    };
  }

  private initFreeDays(events: DateInfo[]): void {
    this.freeDays = events
      .filter((e) => e.type === DayType.Free)
      .map(({ date }) => {
        return {
          date: startOfDayString(date),
          view: format(setTimeZone(date), 'dd.MM.yyyy, EEEE', { locale: ru })
        };
      });
  }

  public onCellClick({ type, date }: WeekDayCell): void {
    if (type === CellType.FreeDay) {
      this.selectedDay = startOfDayString(date);
      this.showFreeSlots$.next(true);
    } else {
      if (isDayAfter(new Date(), date)) {
        this.dayChange$.next(startOfDayString(date));
        this.showFreeSlots$.next(false);
      }
    }
  }

  private setTimelineDate(date: Date): void {
    this.timelineDate = format(date, 'dd.MM.yyyy, EEEE', { locale: ru });
  }

  private dayChangeSub(): void {
    this.dayChange$
      .pipe(
        filter(Boolean),
        tap((day) => {
          const date = setTimeZone(day);
          this.setTimelineDate(date);
          this.dayLoading$.next(true);
          this.getDayInfo(date);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private getDayInfo(date: Date): void {
    const { rRule, committeeId, employeeIds, roomIds, eventId } = this.props;
    const updatedRrule = this.getUpdatedRrule(rRule);
    this.intersectionService
      .dayInfo(toISO(date), updatedRrule, committeeId, employeeIds, roomIds, eventId)
      .pipe(
        tap((day) => {
          this.initFreeTime(day.freeSlots);
          this.timeline = this.busyTimelineService.getTimeline(day, this.props.employees);
        }),
        finalize(() => {
          this.loading$.next(false);
          this.dayLoading$.next(false);
        }),
        catchError((err) => {
          this.error = true;
          return of(err);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  public onSave(): void {
    this.loading$.next(true);
    this.props.type === EventPlanningType.transfer ? this.transferEvent() : this.createUnplannedEvent();
  }

  private createUnplannedEvent(): void {
    this.createEventSwitcher()
      .pipe(
        tap((event) => this.dialogRef.close(event)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private createEventSwitcher(): Observable<ICalendarCommitteeEvent> {
    const { eventId, canceled, committeeId } = this.props;
    const { date } = this.transferDate;

    if (eventId && !canceled) {
      this.jitsuLoggerService.logEvent(CommitteeEventActions.suspendedAndCreateUnplanned, {
        eventId
      });
      return this.eventActionService.suspendAndCreateUplannedEvent(eventId, toISO(date));
    } else {
      this.jitsuLoggerService.logEvent(CommitteeEventActions.openUnplannedDialogButton, {
        committeeId
      });
      return this.calendarService.saveUnplannedEvent({
        committeeId,
        eventDate: toISO(date)
      });
    }
  }

  private transferEvent(): void {
    const { eventId, disconnectCall } = this.props;
    this.jitsuLoggerService.logEvent(CommitteeEventActions.transferEvent, {
      eventId
    });

    this.calendarEventService
      .transferEvent(eventId, {
        newDate: toISO(this.transferDate.date),
        reason: this.reason,
        disconnectCall
      })
      .pipe(
        tap(() =>
          this.dialogRef.close({
            eventTime: this.transferDate.date,
            reason: this.reason
          })
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private initFreeTime(slots: TimeInterval[]): void {
    this.freeTime = slots.map((interval) => {
      const start = format(new Date(interval.timeStart), 'HH:mm');
      const end = format(new Date(interval.timeEnd), ' — HH:mm');
      return {
        ...interval,
        view: start + end
      };
    });
  }

  public onSettingsChange(excludeLunchTime: boolean): void {
    this.excludeLunchTime = excludeLunchTime;
    this.loadEvents$.next(excludeLunchTime);
  }
}
