import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ComBadgeModule,
  ComTableModule,
  EmployeeModule,
  MaterialModule,
  RightPanelModule,
  SharedFormElementsModule
} from '@common/shared';
import { SharedPipesModule } from '@common/pipes';
import { CommitteeEventStatusModule } from '@common/modules/shared/components/committee-event-status/committee-event-status.module';
import { MatBadgeModule } from '@angular/material/badge';
import { TestQuestionComponent } from '@common/dialogs/test-question/test-question.component';
import { IntersectionDialogModule } from '@common/dialogs/intersection-dialog/intersection-dialog.module';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { UpdateEmployeeDialogComponent } from './update-employee-dialog/update-employee-dialog.component';
import { DelegationFormComponent } from './drawer-components/delegation-form/delegation-form.component';
import { EventSidebarComponent } from './drawer-components/event-sidebar/event-sidebar.component';
import { MemberListItemComponent } from './drawer-components/member-list-item/member-list-item.component';
import { EventAgendaSidebarComponent } from './drawer-components/event-agenda-sidebar/event-agenda-sidebar.component';
import { EventActionsComponent } from './event-actions/event-actions.component';
import { EventMaterialsComponent } from './event-materials/event-materials.component';
import { MaterialRestrictAccessComponent } from './material-restrict-access/material-restrict-access.component';
import { RoleDialogComponent } from './role-dialog/role-dialog.component';
import { AgreementEventComponent } from './agreement-event/agreement-event.component';
import { EventMeetingRoomsComponent } from './event-meeting-rooms/event-meeting-rooms.component';
import { EventAccessSidebarComponent } from './drawer-components/event-access-sidebar/event-access-sidebar.component';
import { EmployeeRatingsComponent } from './employee-ratings/employee-ratings.component';
import { MaterialViolationsComponent } from './material-violations/material-violations.component';
import { DisciplineViolationsComponent } from './discipline-violations/discipline-violations.component';
import { UnplannedEventComponent } from './unplanned-event/unplanned-event.component';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';
import { CommitteeDocumentComponent } from './committee-document/committee-document.component';
import { TransferEventDialogComponent } from './transfer-event-dialog/transfer-event-dialog.component';
import { RatePreResolutionDialogComponent } from './rate-pre-resolution-dialog/rate-pre-resolution-dialog.component';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { NewFormElementsModule } from '@common/shared/components/new-form-elements/new-form-elements.module';
import { DaysOffModule } from '@common/shared/components/form-groups/days-off/days-off.module';
import { PlanningDialogModule } from '@common/dialogs/planning-dialog/planning-dialog.module';
import { MaterialViewModule } from '@common/shared/components/material-view/material-view.module';
import { EventCancelSidebarComponent } from '@common/dialogs/drawer-components/event-cancel-sidebar/event-cancel-sidebar.component';
import { EventPlanningModule } from '@common/dialogs/event-planning/event-planning.module';
import { DrawerLoaderComponent } from '@common/dialogs/drawer-components/loader/loader.component';

const DIALOGS = [
  UpdateEmployeeDialogComponent,
  DelegationFormComponent,
  DrawerLoaderComponent,
  EventSidebarComponent,
  MemberListItemComponent,
  EventAgendaSidebarComponent,
  EventCancelSidebarComponent,
  EventActionsComponent,
  EventMaterialsComponent,
  MaterialRestrictAccessComponent,
  RoleDialogComponent,
  AgreementEventComponent,
  EventMeetingRoomsComponent,
  EventAccessSidebarComponent,
  EmployeeRatingsComponent,
  EmployeeListComponent,
  MaterialViolationsComponent,
  DisciplineViolationsComponent,
  UnplannedEventComponent,
  DocumentDialogComponent,
  CommitteeDocumentComponent,
  TransferEventDialogComponent,
  RatePreResolutionDialogComponent,
  TestQuestionComponent,
  TaskEditComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    EmployeeModule,
    SharedPipesModule,
    SharedFormElementsModule,
    ComBadgeModule,
    RightPanelModule,
    CommitteeEventStatusModule,
    MatBadgeModule,
    ComTableModule,
    IntersectionDialogModule,
    PlanningDialogModule,
    NewFormElementsModule,
    DaysOffModule,
    MaterialViewModule,
    EventPlanningModule
  ],
  declarations: [...DIALOGS],
  exports: [...DIALOGS]
})
export class SharedDialogsModule {}
