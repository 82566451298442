import { IEmployee, IEmployeeOption, IMemberDto, IOption, IEmployeeShort } from '@common/types';
import { IMemberModel } from '@common/shared/components/form-groups/members/members.types';

export const employeeOptionMapper = (employee: IEmployee | IEmployeeShort): IEmployeeOption | null => {
  if (!employee) return null;
  return {
    id: employee.id,
    name: employee.fullName,
    isActive: employee.isActive,
    position: optionMapper(employee.position),
    division: optionMapper(employee.division)
  };
};

export const memberModelToMemberDtoMapper = ({ employeeOption, role }: IMemberModel): IMemberDto => {
  return {
    position: employeeOption.position?.name,
    employee: employeeOption
      ? {
          id: employeeOption.id as string,
          fullName: employeeOption.name,
          position: employeeOption.position?.name,
          division: employeeOption.division?.name,
          shortName: employeeOption.name
        }
      : null,
    roleId: role.id,
    role
  };
};

export const optionMapper = (name: string): IOption | undefined => {
  if (!name) return;
  return {
    name,
    id: name
  };
};
