import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';
import { ErrorsEnum } from '@common/enums';
import { IOption } from '@common/types';
import { isAfter, isSameDay, parse, startOfDay } from 'date-fns';


export const selectOptionValidator =
  (options: IOption[]): ValidatorFn =>
  (control: FormControl): ValidationErrors | null =>
    control.value && !options.map(({ id }) => id).includes(control.value)
      ? { [ErrorsEnum.SelectOptionError]: true }
      : null;

export const dateValidator =
  ({ min, max }: { min?: string | Moment; max?: string | Moment }): ValidatorFn =>
  (control: FormControl): ValidationErrors | null => {
    const curr = moment(control.value);
    const minDate = min ? moment(min).startOf('day') : null;
    const maxDate = max ? moment(min).startOf('day') : null;
    if (minDate && maxDate) {
      return minDate.isAfter(curr) && maxDate.isBefore(curr) ? { min: true, max: true } : null;
    } else if (minDate) {
      return minDate.isAfter(curr) ? { min: true } : null;
    } else if (maxDate) {
      return maxDate.isBefore(curr) ? { max: true } : null;
    }
    return null;
  };

export const minTimeValidator =
  (timezone: string, date?: string | Moment): ValidatorFn =>
  (control: FormControl): ValidationErrors | null => {
    if (control.value && date && moment.tz(date, timezone).isSame(moment(), 'date')) {
      const [hour, minute] = control.value?.split(':');
      const curr = moment.tz(timezone).set({ h: +hour, m: +minute });

      return curr.isBefore(moment()) ? { min: true } : null;
    }
    return null;
  };

export const minDateTimeValidator =
  (minDateTime?: string | Moment): ValidatorFn =>
  (control: FormControl): ValidationErrors | null => {
    const min = moment(minDateTime);
    const curr = moment(control.value);
    return min.isAfter(curr) ? { min: true } : null;
  };

export const isSameDateTimeValidator =
  (dateTime?: string | Moment): ValidatorFn =>
  (control: FormControl): ValidationErrors | null => {
    const min = moment(dateTime);
    const curr = moment(control.value);
    return min.isSame(curr) ? { isSame: true } : null;
  };

export const multiselectOptionValidator =
  (control: FormControl<(string | number)[]>): ValidatorFn =>
  (inputControl: FormControl<string>): ValidationErrors | null => {
    if (inputControl.value) {
      addError(control, ErrorsEnum.MultiselectOptionError);
    } else {
      removeError(control, ErrorsEnum.MultiselectOptionError);
    }
    return null;
  };

export const listMaxLengthValidator =
  (maxLength: number): ValidatorFn =>
  (control: FormArray): ValidationErrors | null =>
    control.length > maxLength ? { maxLength: true } : null;

const addError = (control: AbstractControl, error: ErrorsEnum): void => {
  control.setErrors({
    ...control.errors,
    [error]: true
  });
};

const removeError = (control: AbstractControl, error: ErrorsEnum): void => {
  if (control.errors) {
    const { [error]: errorToRemove, ...errors } = control.errors;
    control.setErrors(Object.entries(errors).length ? errors : null);
  }
};

export const dateValidatorEqualOrBeforeToday =
  (): ValidatorFn =>
  (control: FormControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const inputDate = startOfDay(parse(control.value, 'yyyy-MM-dd', new Date()));
    const today = startOfDay(new Date());

    return isSameDay(inputDate, today) || isAfter(inputDate, today) ? null : { max: true };
  };
