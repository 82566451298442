<div class="flex flex-col">
  <div class="cover-block bg-gray-200" style="background-image: url('/assets/images/cards/01-320x200.jpg')">
    <span class="img-wrapper mx-3 bg-gray-200">
      <ng-container *ngIf="!userInfo.avatar">{{ userInfo.fullName | slice: 0 : 1 }}</ng-container>
      <img *ngIf="userInfo.avatar" [src]="userInfo.avatar" [alt]="userInfo.fullName" />
      <span class="user-status" [ngClass]="userInfo.isActive ? 'bg-limeGreen' : 'bg-red-600'">
        {{ userInfo.isActive ? 'Активен' : 'Заблокирован' }}
      </span>
    </span>
    <div class="absolute top-2 right-4 cursor-pointer">
      <mat-icon class="text-secondary" (click)="closeDrawer.emit()" svgIcon="heroicons_outline:x"></mat-icon>
    </div>
  </div>
  <div class="px-3">
    <h1 class="text-xl font-bold mb-4">{{ userInfo.fullName }}</h1>
    <div class="flex pb-3">
      <mat-icon class="mr-2" svgIcon="heroicons_outline:user"></mat-icon>
      <span class="text-sm">{{ userInfo.appRole?.name || '-' }}</span>
      <span class="text-gray-500 text-sm">::Роль</span>
    </div>
    <div class="flex pb-3">
      <mat-icon class="mr-2" svgIcon="heroicons_outline:office-building"></mat-icon>
      <span class="text-sm">{{ userInfo.department || '-' }}</span>
      <span class="text-gray-500 text-sm">::Подразделение</span>
    </div>
    <div class="flex pb-3">
      <mat-icon class="mr-2" svgIcon="heroicons_outline:mail"></mat-icon>
      <a href="mailto:{{ userInfo.email }}" class="text-sm text-blue-500 underline">{{
        userInfo.email || '-'
      }}</a>
      <span class="text-gray-500 text-sm">::Email</span>
    </div>
  </div>
</div>
