import { Component, Inject } from '@angular/core';
import { IEmployeeRating } from '@common/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'com-employee-ratings',
  templateUrl: './employee-ratings.component.html'
})
export class EmployeeRatingsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IEmployeeRating[]) {}
}
